var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-header cols"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$tfo('search_results')))])])]),_c('el-row',{staticClass:"cols--center mar-v--1"},[_c('div',{staticClass:"cell"}),_c('div',{staticClass:"table-pagination pull-right mar-v--1",attrs:{"name":"table-pagination"}},[_c('table-navigation',{attrs:{"state":_vm.state}})],1)]),_c('el-table',{class:_vm.model.name + '-table',attrs:{"name":_vm.model.name + '-table',"stripe":"","size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items}},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('id'),"width":"60"}}),_c('el-table-column',{attrs:{"prop":"thumbnail","label":_vm.$tf('common.detected'),"width":"176"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('object-thumbnail',{attrs:{"url":row.thumbnail || '',"frame":row.fullframe || ''},on:{"preview":function (v) { return _vm.showImage(row.fullframe, _vm.getBox(row)); }}})]}}])}),_c('el-table-column',{attrs:{"prop":"match","label":_vm.$tf('common.matched_to'),"width":"176"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.face_object_id)?_c('object-thumbnail',{attrs:{"id":row.face_object_id},on:{"preview":function (v) { return _vm.showImage(v && v.src, null); }}}):_vm._e(),(row.case_face_id)?_c('object-thumbnail',{attrs:{"case-face-id":row.case_face_id},on:{"preview":function (v) { return _vm.showImage(v && v.src, null); }}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$tf('name'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dossier)?_c('dossier-table-part-item',{key:'item' + row.dossier,attrs:{"confidence":row.confidence,"by-dossier-id":row.dossier}}):(row.case_face_id)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$tfo('case_face_id'))+": "+_vm._s(row.case_face_id)+" ("+_vm._s(_vm.$filters.floatToPercent(row.confidence))+")")])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"date","width":"120px","label":_vm.$tf('date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("parseDate")(row.external_timestamp)))),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm._f("parseDate")(row.external_timestamp)))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"dates","width":"120px","label":_vm.$tf('camera')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.camera)+" "),_c('br'),_c('span',{staticClass:"text-tetriary"},[_vm._v(" "+_vm._s(row.puppet_id)+" ")])]}}])})],1),_c('div',{staticClass:"table-pagination pull-right mar-v--1",attrs:{"name":"table-pagination"}},[_c('table-navigation',{attrs:{"state":_vm.state}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }